<template>
      <div id="pageParametersForm" class="form">
        <v-form v-if="loaded" ref="form" v-model="valid" lazy-validation>
          <v-text-field
              v-model="pageParameters.name"
              label="Name"
              :required="false"
            />
            <v-text-field
              v-model="pageParameters.page_name"
              label="Page Name"
              :required="false"
            />
            <v-text-field
              v-model="pageParameters.vuex_name"
              label="Vuex Name"
              :required="false"
            />
            <v-text-field
              v-model="pageParameters.owner_only"
              label="Owner Only"
              :required="false"
            />
            <v-text-field
              v-model="pageParameters.created_by"
              label="Created By"
              :required="false"
            />
            <v-text-field
              v-model="pageParameters.create_time"
              label="Created on"
              :required="true"
            />
            
        <div class="mt-4 d-flex justify-space-between">
        <v-btn @click="goBack">
          Back
        </v-btn>
        <v-btn @click="handleSubmit" color="primary">
          {{ id ? "Update" : "Save" }}
        </v-btn>
        <span></span>
      </div>
        </v-form>
      </div>
    </template>
    
<script>
  import { getPageParameters } from "@/services/pageParameters";
  
  
  import { mapActions } from "vuex";

  

  export default {
    props: ["id"],
    data () {
      return {
        loaded: false,
        valid: false,
        pageParameters: {
          relations: {
            
          },
          name: '',
page_name: '',
vuex_name: '',
owner_only: '',
created_by: '',
create_time: '',

        }
      }
    },
    created() {
      this.setInstance();
    },
    methods: {
      ...mapActions("pageParameters", ["createPageParameters", "updatePageParameters"]),
      ...mapActions("messages", [
        "addMessage",
        "addErrorMessage",
        "addSuccessMessage"
      ]),
      
      async handleSubmit() {
        if (this.id) {
          let pageParameters = this.pageParameters;
          delete pageParameters.relations;

          this.updatePageParameters(pageParameters)
          .then( (response) => {
            console.log(response);
            this.addSuccessMessage("PageParameters was updated successfully");
            this.goBack();
          }).catch( () => {
            this.addErrorMessage("Error updating PageParameters, please try again later");
          });
        } else {
          let pageParameters = this.pageParameters;
          delete pageParameters.relations;

          this.createPageParameters(pageParameters)
          .then( response => {
            console.log(response);
            this.addSuccessMessage("PageParameters was created successfully");
            this.goBack();
          }).catch( () => {
            this.addErrorMessage("Error creating PageParameters, please try again later");
          })
        }
      },
      goBack() {
        this.$router.go(-1);
      },
      setInstance() {
        if (this.id) {
          this.pageParameters.id = this.id;
          getPageParameters(this.id)
            .then(response => {
              let instance = response.data;
              for (let property in instance) {
                if (Object.prototype.hasOwnProperty.call(instance, property) && Object.prototype.hasOwnProperty.call(this.pageParameters, property)) {
                  this.pageParameters[property] = instance[property];
                }
              }
              this.loaded = true;
            })
        } else {
          this.loaded = true;
        }
        this.setDependencies();
      },
      setDependencies() {
        
      }
    },
    components: {
      
    }
  }
</script>
